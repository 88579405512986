export default {
	cinema: {
		address: 'ул. Менделеева, 35а',
		place: null,
		phoneNumbers: ['+7 (8555) 36-20-40', '+7 (917) 236-20-40'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/suncity_nk',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CCCweTOU',
		selectButton: {
			options: [
				{
					value: '//suncity-nk.ru',
					title: 'ТЦ Барс-3',
				},
				{
					value: '//ramusmall.suncity-nk.ru',
					title: 'ТЦ Рамус Молл',
				},
			],
			selectedOptionValue: '//suncity-nk.ru',
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
